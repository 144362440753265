.App {
  text-align: center;
}


.seats {
  position: absolute;
  /*絶対配置*/
}


.desk{
  position: absolute;
  margin:-28px 0 0 -28px;
  width:56px;
  height:56px;
  /*color: red;*/
  /*white-space: nowrap;*/
  /*font-size:90%;*/
}

/*絶対位置は中心を指定する*/

.A1 {
  position: absolute;
  /*絶対配置*/
  top: 64px;
  left: 222px;
}
.A2 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 278px;
}
.A3 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 334px;
}
.A4 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 390px;
}
.A5 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 446px;
}
.A6 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 504px;
}
.A7 {
  position: absolute;
  /*絶対配置*/
  top: 63px;
  left: 561px;
}
.A8 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 222px;
}
.A9 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 278px;
}
.A10 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 334px;
}
.A11 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 390px;
}
.A12 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 446px;
}
.A13 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 504px;
}
.A14 {
  position: absolute;
  /*絶対配置*/
  top: 158px;
  left: 561px;
}
.A15 {
  position: absolute;
  /*絶対配置*/
  top: 230px;
  left: 280px;
}
.A16 {
  position: absolute;
  /*絶対配置*/
  top: 230px;
  left: 342px;
}
.A17 {
  position: absolute;
  /*絶対配置*/
  top: 270px;
  left: 220px;
}
.A18 {
  position: absolute;
  /*絶対配置*/
  top: 332px;
  left: 220px;
}
.A19 {
  position: absolute;
  /*絶対配置*/
  top: 394px;
  left: 220px;
}
.A20 {
  position: absolute;
  /*絶対配置*/
  top: 434px;
  left: 280px;
}
.A21 {
  position: absolute;
  /*絶対配置*/
  top: 434px;
  left: 342px;
}
.A22 {
  position: absolute;
  /*絶対配置*/
  top: 270px;
  left: 394px;
}
.A23 {
  position: absolute;
  /*絶対配置*/
  top: 332px;
  left: 394px;
}
.A24 {
  position: absolute;
  /*絶対配置*/
  top: 394px;
  left: 394px;
}
.A25 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 214px;
}
.A26 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 306px;
}
.A27 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 214px;
}
.A28 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 306px;
}
.A29 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 356px;
}
.A30 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 452px;
}
.A31 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 356px;
}
.A32 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 452px;
}
.A33 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 504px;
}
.A34 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 594px;
}
.A35 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 504px;
}
.A36 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 594px;
}
.A37 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 650px;
}
.A38 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 740px;
}
.A39 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 650px;
}
.A40 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 740px;
}
.A41 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 794px;
}
.A42 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 886px;
}
.A43 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 794px;
}
.A44 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 886px;
}
.A45 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 940px;
}
.A46 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 1034px;
}
.A47 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 940px;
}
.A48 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 1034px;
}
.A49 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 1084px;
}
.A50 {
  position: absolute;
  /*絶対配置*/
  top: 608px;
  left: 1176px;
}
.A51 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 1084px;
}
.A52 {
  position: absolute;
  /*絶対配置*/
  top: 666px;
  left: 1176px;
}
.A53 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 824px;
}
.A54 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 884px;
}
.A55 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 946px;
}
.A56 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 1006px;
}
.A57 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 1068px;
}
.A58 {
  position: absolute;
  /*絶対配置*/
  top: 412px;
  left: 1128px;
}
.A59 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 824px;
}
.A60 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 884px;
}
.A61 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 946px;
}
.A62 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 1006px;
}
.A63 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 1068px;
}
.A64 {
  position: absolute;
  /*絶対配置*/
  top: 512px;
  left: 1128px;
}
.A65 {
  position: absolute;
  /*絶対配置*/
  top: 272px;
  left: 1586px;
}
.A66 {
  position: absolute;
  /*絶対配置*/
  top: 272px;
  left: 1650px;
}
.A67 {
  position: absolute;
  /*絶対配置*/
  top: 272px;
  left: 1717px;
}
.A68 {
  position: absolute;
  /*絶対配置*/
  top: 272px;
  left: 1782px;
}
.A69 {
  position: absolute;
  /*絶対配置*/
  top: 348px;
  left: 1586px;
}
.A70 {
  position: absolute;
  /*絶対配置*/
  top: 348px;
  left: 1650px;
}
.A71 {
  position: absolute;
  /*絶対配置*/
  top: 348px;
  left: 1717px;
}
.A72 {
  position: absolute;
  /*絶対配置*/
  top: 348px;
  left: 1782px;
}
.A73 {
  position: absolute;
  /*絶対配置*/
  top: 468px;
  left: 1586px;
}
.A74 {
  position: absolute;
  /*絶対配置*/
  top: 468px;
  left: 1650px;
}
.A75 {
  position: absolute;
  /*絶対配置*/
  top: 468px;
  left: 1717px;
}
.A76 {
  position: absolute;
  /*絶対配置*/
  top: 468px;
  left: 1782px;
}
.A77 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1284px;
}
.A78 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1348px;
}
.A79 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1412px;
}
.A80 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1478px;
}
.A81 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1284px;
}
.A82 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1348px;
}
.A83 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1412px;
}
.A84 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1478px;
}
.A85 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1586px;
}
.A86 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1650px;
}
.A87 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1717px;
}
.A88 {
  position: absolute;
  /*絶対配置*/
  top: 536px;
  left: 1782px;
}
.A89 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1586px;
}
.A90 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1650px;
}
.A91 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1717px;
}
.A92 {
  position: absolute;
  /*絶対配置*/
  top: 658px;
  left: 1782px;
}
.A93 {
  position: absolute;
  /*絶対配置*/
  top: 844px;
  left: 266px;
}
.A94 {
  position: absolute;
  /*絶対配置*/
  top: 844px;
  left: 584px;
}
.A95 {
  position: absolute;
  /*絶対配置*/
  top: 844px;
  left: 714px;
}
.A96 {
  position: absolute;
  /*絶対配置*/
  top: 844px;
  left: 838px;
}




.example img {
  width: 100%;
}
